<template>
  <main>
    <section class="text-center">
      <div :class="`page-${status}`">
        <div class="verify" style="width: 150px; margin: 10px auto">
          <LoginLogoIcon /><br /><br />
          <LoginLogoName />
        </div>
        <div>
          <h1 v-if="status === 'success'">
            <i class="fa fa-exclamation-circle"></i> Email verified.
          </h1>
          <h1 v-if="status === 'error'">
            <i class="fa fa-exclamation-circle"></i> Link expired.
          </h1>
          <p>{{ text }}</p>
          <p>
            <a class="btn btn-lg btn-primary" href="/#/login">Login</a>
            <a
              v-if="status === 'error'"
              class="btn btn-lg btn-primary"
              href="/#/register"
              >Register</a
            >
          </p>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
import LoginLogoIcon from "@/components/LoginLogoIcon.vue";
import LoginLogoName from "@/components/LoginLogoName.vue";
import api from "@/lib/api";
export default {
  name: "Verify",
  components: {
    LoginLogoIcon,
    LoginLogoName,
  },
  data() {
    return {
      status: "pending",
      text: "Validating...",
    };
  },
  mounted() {
    api
      .get("/api/login")
      .then((response) => {
        // console.log(response.data.csrfToken);
        this.checkVerify(response.data.csrfToken);
      })
      .catch((errors) => {
        console.log(errors.response);
        console.log(errors.response.data.error);
      });
  },
  methods: {
    checkVerify(CSRF) {
      api
        .post("/api/verify", { uid: this.$route.params.uid, _csrf: CSRF })
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            this.text = "Thank you for your verification. Please login.";
            this.status = "success";
          }
        })
        .catch((errors) => {
          console.log(errors.response);
          console.log(errors.response.data.error);
          if (errors.response.status === 401) {
            this.text = errors.response.data;
            this.status = "error";
          }
        });
    },
  },
};
</script>
